<template>
  <section class="">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h4 class="">Donation records</h4>
      <slot></slot>
    </div>
    <el-table style="width: 100%" :data="jciList" header-cell-class-name="eleTableHeader">
      <el-table-column align="center" type="index" label="#" width="60">
      </el-table-column>
      <el-table-column align="center" prop="accept_donor" label="Donated organization" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="id" label="DonateID " show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" label="Payment method">
        <template slot-scope="scope">
          {{ {1:'Paypal',2:'Offline Remittance',3:'Batch payment',4:'Credit card payment'}[scope.row.pay_type] }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="donation_amount" label="Amount(USD)">
      </el-table-column>
      <el-table-column align="center" prop="donation_at" label="Datetime" show-overflow-tooltip>
      </el-table-column>
    </el-table>
  </section>
</template>
<script>
  export default {
    name: "",
    components: {},
    props: {
      jciList: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {};
    },
    mounted() { },
    methods: {},
  };
</script>
<style src='' lang='scss' scoped>
  ::v-deep .eleTableHeader {
    background: #f4f7f9;
    font-size: 14px;
    font-weight: 500;
    color: #3f414d;
  }
</style>