<template>
  <section class="my_donate">
    <iq-card>
      <tab-nav :pills="true" id="pills-tab" class="
          nav nav-pills
          d-flex
          align-items-center
          justify-content-center
          profile-feed-items
          p-0
          m-0
        ">
        <tab-nav-items class="col-sm-6 p-0" :active="true" id="pills-feed-tab" href="#profile-feed"
          ariaControls="pills-home" role="tab" @click.native="changeTab(1)" :ariaSelected="true" title="JCI" />
        <tab-nav-items class="col-sm-6 p-0" @click.native="changeTab(2)" :active="false" id="pills-activity-tab"
          href="#profile-activity" ariaControls="pills-profile" role="tab" :ariaSelected="false"
          title="JCI Foundation" />
        <!-- <tab-nav-items class="col-sm-4 p-0" :active="false" @click.native="changeTab(3)" id="pills-friend-tab"
          href="#profile-friends" ariaControls="pills-contact" role="tab" :ariaSelected="false" title="Other" /> -->
      </tab-nav>
    </iq-card>
    <div class="tab-content">
      <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
        <div class="iq-card mb-0 my_card">
          <div class="iq-card-body">
            <JCI :jciList="jciList">
              <!-- <el-button @click="clearPopup" type="primary" plain>Add records</el-button> -->
            </JCI>
          </div>
        </div>
      </tab-content-item>
      <tab-content-item :active="false" id="profile-activity" aria-labelled-by="pills-activity-tab">
        <div class="iq-card mb-0 my_card">
          <div class="iq-card-body">
            <Foundation :dationList="dationList">
              <!-- <el-button @click="clearPopup" type="primary" plain>Add records</el-button> -->
            </Foundation>
          </div>
        </div>
      </tab-content-item>
      <tab-content-item :active="false" id="profile-friends" aria-labelled-by="pills-friend-tab">
        <div class="iq-card mb-0 my_card">
          <div class="iq-card-body">
            <Other :otherList="otherList">
              <!-- <el-button @click="clearPopup" type="primary" plain>Add records</el-button> -->
            </Other>
          </div>
        </div>
      </tab-content-item>
    </div>

    <iq-card class="pb-3 pag_card" style="text-align: right;">
      <el-pagination background layout="prev, pager, next" :total="total" :current-page="page" :page-size="size"
        @current-change="changePage" hide-on-single-page>
      </el-pagination>
    </iq-card>

    <el-dialog :visible.sync="dialogVisible">
      <form class="needs-validation" novalidate action="#">
        <div class="form-row">
          <div class="col-md-6">
            <label for="validationCustomUsername" class="appendAsterisk">First Name</label>
          </div>
          <div class="col-md-6">
            <label for="validationCustomUsername" class="appendAsterisk">Last Name</label>
          </div>
          <div class="col-md-6 mb-3">
            <input v-model="donor_first_name" class="form-control birthdaySelect" />
          </div>
          <div class="col-md-6 mb-3">
            <input v-model="donor_last_name" class="form-control birthdaySelect" />
          </div>
          <div class="col-md-12 mb-3">
            <label for="validationCustomUsername" class="appendAsterisk">Donor Email</label>
            <input v-model="donor_email" type="email" class="form-control birthdaySelect" />
          </div>
          <div class="col-md-12 mb-3">
            <label for="validationCustomUsername" class="appendAsterisk">Donation Type</label>
            <select class="form-control birthdaySelect" v-model="donated_type" disabled>
              <option value="1" label="JCI"></option>
              <option value="2" label="JCI Foundation"></option>
              <option value="3" label="Other"></option>
            </select>
            <!-- <input v-model="donated_type" class="form-control birthdaySelect" disabled/> -->
          </div>

          <div class="mb-3 p-2 w-100" v-if="donated_type!=2">
            <div>Organization</div>
            <div v-if="donated_type==1">
              <OrganizationSel @getOrganizationParams="getOrganizationParams" />
            </div>
            <div v-else>
              <el-select class="w-100" v-model="organization_id" filterable remote reserve-keyword
                :remote-method="organizationSearch" placeholder="Enter keyword search organization">
                <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <label for="validationCustomUsername" class="appendAsterisk">Donation amount</label>
            <div class="self_pre_input d-flex align-item-center">
              <span>USD</span>
              <input type="text" class="form-control" id="selfInput" aria-describedby="inputGroupPrepend2"
                v-model="donation_amount" />
            </div>
          </div>
          <div class="col-md-12 mb-3">
            <label for="validationCustomUsername" class="appendAsterisk">Payment method</label>
            <select class="form-control birthdaySelect" v-model="pay_type">
              <option value="0">To-balance</option>
              <option value="1">PayPal</option>
              <option value="2">Single offline payment</option>
              <option value="3">Batch offline payment</option>
            </select>
          </div>
          <div class="w-100" v-if="pay_type == 2 || pay_type == 3">
            <div class="col-md-12 mb-3">
              <label for="validationCustomUsername" class="appendAsterisk">Order No.</label>
              <input v-model="order_number" class="form-control birthdaySelect" />
            </div>
            <div class="col-md-12 mb-3">
              <label for="validationCustomUsername" class="appendAsterisk">Proof of identity</label>
              <div class="col-2">
                <el-upload class="avatar-uploader" action="no" v-loading="loading" :show-file-list="false"
                  :http-request="uploadVoucher">
                  <img class="avatar-100" v-if="offline_certificate && fileType != 'application/pdf'"
                    :src="offline_certificate" alt="" />
                  <img v-else src="../../../assets/images/settings/camera.png" alt="" />
                </el-upload>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr />
      <div class="d-flex justify-content-end">
        <button type="reset" class="btn btn-danger mr-2" @click="dialogVisible=false">Cancel</button>
        <button class="btn btn-primary" @click="addDonate">Submit</button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
  import JCI from "./components/JCITable";
  import Foundation from "./components/FundationTable";
  import Other from "./components/OtherTable";
  import OrganizationSel from "@/components/ComComponents/OrganazationSel";
  import { mapState } from "vuex";
  export default {
    name: "",
    components: { JCI, Foundation, Other, OrganizationSel },

    data() {
      return {
        form: {},
        dialogVisible: false,
        loading: false,
        jciList: [],
        dationList: [],
        otherList: [],

        orgList: [],
        tabType: 1,

        user_id: "",
        donated_type: 1,
        organization_id: "",
        donation_amount: 100,
        donor_first_name: "",
        donor_last_name: "",
        donor_email: "",
        pay_type: "",
        offline_certificate: "",
        order_number: "",
        page: 1,
        size: 10,
        total: 10
      };
    },
    computed: {
      ...mapState(["userInfo"]),
    },
    mounted() {
      this.getMyDonation();
    },
    methods: {
      clearPopup() {
        this.dialogVisible = true
        this.donation_amount = 100;
        this.donor_first_name = "";
        this.donor_last_name = "";
        this.donor_email = "";
        this.organization_id = "";
        this.pay_type = "";
        this.offline_certificate = "";
        this.order_number = "";
      },

      changeTab(type) {
        this.tabType = type;
        this.donated_type = type;
        this.page = 1
        this.getMyDonation();
      },

      addDonate() {
        let userInfo = JSON.parse(this.$getlocalStorage("userInfo"));
        let data = {}
        if (this.pay_type == 2 || this.pay_type == 3) {
          data = {
            offline_certificate: this.offline_certificate,
            order_number: this.order_number,
          }
        }
        if (this.donated_type != 2) data.organization_id = this.organization_id
        this.$http
          .addDonate({
            user_id: userInfo.user_id,
            donated_type: this.donated_type,
            donation_amount: this.donation_amount,
            donor_first_name: this.donor_first_name,
            donor_last_name: this.donor_last_name,
            donor_email: this.donor_email,
            pay_type: this.pay_type,
            ...data
          })
          .then((res) => {
            if (res.status == 200) {
              this.getMyDonation();
              this.dialogVisible = false;
              this.user_id = "";
              this.donation_amount = "";
              this.donor_first_name = "";
              this.donor_last_name = "";
              this.donor_email = "";
              this.organization_id = "";
              this.pay_type = "";
              this.offline_certificate = "";
              this.order_number = "";
            }
          });
      },

      organizationSearch(query) {
        this.$http
          .getOtherOrganizationList({ name: query })
          .then((res) => {
            if (res.status == 200) {
              this.orgList = res.data;
            }
          });
      },

      // 选择组织
      getOrganizationParams(data) {
        let OrganData = JSON.parse(JSON.stringify(data));
        let level = OrganData.level.id;
        switch (level) {
          case 0: {
            // jci
            this.organization_id = OrganData.jci.id;
            break;
          }
          case 4: {
            // area
            this.organization_id = OrganData.area.id;
            break;
          }
          case 2: {
            // nation
            this.organization_id = OrganData.national.id;
            break;
          }
          case 3: {
            // region
            this.organization_id = OrganData.regional.id;
            break;
          }
          case 1: {
            // local
            this.organization_id = OrganData.local.id;
            break;
          }
        }
      },

      uploadVoucher(params) {
        this.loading = true;
        var formData = new FormData();
        formData.append("file", params.file);
        this.fileType = formData.get("file").type;
        this.$http.uploadImage(formData).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            this.offline_certificate = res.data.toString();
          }
        });
      },
      getMyDonation() {
        let userInfo = JSON.parse(this.$getlocalStorage("userInfo"));
        this.$http
          .getMyDonation({
            donated_type: this.tabType,
            user_id: userInfo.user_id,
            page: this.page,
            per_page: this.size
          })
          .then((res) => {
            if (res.status == 200) {
              // console.log(res.data.data);
              if (this.tabType == 1) {
                this.jciList = res.data.data;
                this.total = res.data.total
              } else if (this.tabType == 2) {
                this.dationList = res.data.data;
                this.total = res.data.total
              } else {
                this.otherList = res.data.data;
                this.total = res.data.total
              }
            }
          });
      },

      changePage(value) {
        this.page = value
        this.getMyDonation()
      }
    },
  };
</script>
<style lang='scss' scoped>
  .my_donate {
    .my_card {
      border-radius: 5px 5px 0 0;
      box-shadow: none;
    }

    .pag_card {
      border-radius: 0 0 5px 5px;
      box-shadow: none;
    }
  }

  .self_pre_input {
    >span {
      display: block;
      width: 122px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      background: #f8f8f8;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
    }

    ::v-deep .form-control {
      border-radius: 0;
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
    }
  }

  ::v-deep .el-input__inner {
    font-size: 14px !important;
    border-radius: 10px !important;

    >input {
      height: 45px !important;
      line-height: 45px !important;
    }
  }
</style>