import { render, staticRenderFns } from "./JCITable.vue?vue&type=template&id=8035a954&scoped=true&"
import script from "./JCITable.vue?vue&type=script&lang=js&"
export * from "./JCITable.vue?vue&type=script&lang=js&"
import style0 from "./JCITable.vue?vue&type=style&index=0&id=8035a954&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8035a954",
  null
  
)

export default component.exports